<template>
    <div>
        <page-settings :page="page" :update="true"></page-settings>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import Auth from "@/mixins/Auth";
    import ModalNavigation from "@/mixins/ModalNavigation";
    import PageSettings from "@/components/cms/websites/PageSettings";

    export default {
        components: { PageSettings },
        mixins: [ ModalNavigation, Auth ],
        data: () => {
            return {
                page: {},
            }
        },
        computed: {
            ...mapGetters({
                getPageBySlugAndWebsiteId: 'pages/getPageBySlugAndWebsiteId',
            })
        },
        created() {
            this.page = this.getPageBySlugAndWebsiteId(this.$route.params.pageSlug, this.$route.params.websiteId);
            this.page.customers_id = this.customerId;
            this.setActiveModalTitle('edit "' + this.page.website_pages_name + '"');
        }
    }
</script>